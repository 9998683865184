.new-table .ant-btn {
  @apply flex items-center justify-center disabled:flex disabled:items-center disabled:justify-center;
}

.new-table .ant-table-wrapper {
  @apply h-full bg-white rounded;
}

.new-table .ant-spin-nested-loading,
.ant-spin-container {
  @apply h-full;
}

.new-table .ant-table {
  @apply flex flex-col h-full max-h-full bg-transparent;
}

.new-table .ant-table-title {
  @apply flex items-center justify-between bg-white h-12 border-none !important;
}

.new-table .ant-table-container {
  @apply overflow-y-auto border rounded grow scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 !important;
}

.new-table .ant-table-thead {
  @apply sticky top-0 z-10 flex-initial;
}

.new-table .ant-table-tbody {
  @apply h-full;
}

.new-table .ant-table-footer {
  @apply flex items-center border-none justify-between bg-white h-12 !important;
}
.ant-tabs-content.ant-tabs-content-top {
  @apply h-full !important;
}
.ant-tabs-nav {
  @apply mb-1 !important;
}

.change-row .ant-table-cell {
  @apply bg-blue-50  !important;
}

.disabled-row {
  @apply bg-red-100 pointer-events-none !important;
}

.change-row .ant-table-cell-row-hover {
  @apply bg-blue-100 transition-all duration-300 ease-in-out !important;
}

.ant-switch {
  @apply bg-gray-300 !important;
}
.ant-switch-checked {
  @apply bg-blue-500 !important;
}
