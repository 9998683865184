@tailwind base;
@tailwind components;
@tailwind utilities;

.d3-table-cell .ant-table-cell{
   @apply p-0 !important;
}


.ant-btn-primary {
    color: #fff;
    background-color: #1677ff;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  }
